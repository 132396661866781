<template>
	<div>
		<div v-if="dataImg == null" class="text-center pointer" @click="$refs.updateImgInput.click()">
		<vs-avatar v-if="!participant" size="70px">
			<img src="@/assets/images/file-icons/default-img.jpg" alt="asd" class="responsive pointer" />
		</vs-avatar>
		<vs-avatar class="text-4xl" :color="getColor(participant && participant.name)" :text="getInitial(participant && participant.name)" v-if="participant" size="70px">
		</vs-avatar>
		</div>
		<div v-if="dataImg != null" class="text-center" @click="$refs.updateImgInput.click()">
			<img :src="dataImg" alt="img" style="width: 70px; height: 70px" class="responsive rounded-full" />
		</div>
		<div class="space-y-6">
			<div>
				<div class="text-gray border-bottom-2">{{ $t('Name') }}</div>
				<div>{{dataName}}</div>
			</div>
			<div>
				<div class="text-gray">{{ $t('Email') }}</div>
				<div>{{dataEmail}}</div>
			</div>
			<div>
				<div class="text-gray">{{ $t('Company') }}</div>
				<div>{{dataCompany || '-' }}</div>
			</div>
      <div>
				<div class="text-gray">{{ $t('Access Code') }}</div>
				<div>{{dataCode || '-' }}</div>
			</div>
      <div class="flex">
        <div v-if="!isExtend">
          <div class="text-gray">{{ $t('Table') }}</div>
          <div>{{dataTable}}</div>
        </div>
        <div v-if="isExtend">
          <div class="text-gray">{{ $t('Country') }}</div>
          <div>{{dataCountry || '-' }}</div>
        </div>
      </div>
      <div class="flex min-w-full justify-between" v-if="isExtend">
        <div class="w-full">
          <div class="text-gray text-center">{{ $t('Seat') }}</div>
          <div class="text-center">{{dataSeat || '-'}}</div>
        </div>
        <span class="ml-3 mr-3">|</span>
        <div class="w-full">
          <div class="text-gray  text-center">{{ $t('Room') }}</div>
          <div class="text-center">{{dataRoom || '-'}}</div>
        </div>
        <span class="ml-3 mr-3">|</span>
        <div class="w-full">
          <div class="text-gray  text-center">{{ $t('Table') }}</div>
          <div class="text-center">{{dataTable || '-'}}</div>
        </div>
      </div>

      <div>
        <profile-q-r v-if="isShowQr" :profile="participant" />
      </div>

      <!-- only if hybrid -->
      <vs-checkbox v-if="event && event.is_hybrid && dataIsAssignSeat" v-model="dataIsAssignSeat" class="mb-4 mt-8 ml-0 pl-0" disabled>{{$t('Seat Assigned')}} ({{ eventSeats }} {{ $t('seats left') }})</vs-checkbox>
      <vs-checkbox v-if="event && event.is_hybrid && !dataIsAssignSeat" v-model="dataIsAssignSeat" class="mb-4 mt-8 ml-0 pl-0" disabled>{{$t('Seat Unassigned')}} ({{ eventSeats }} {{ $t('seats left') }})</vs-checkbox>

      <div v-if="dataNotes">
        <div class="mt-8 text-secondary">{{ $t('Notes') }}</div>
        {{ dataNotes }}
      </div>
		</div>
		<div class="mt-8 flex justify-between items-center">
			<div class="text-blue pointer" @click="showDeleteParticipant()">
				Delete this profile
			</div>
      <div class="flex">
        <div>
          <vs-button
            @click="showQr()"
            color="secondary"
            type="filled"
            class="submit-button mr-2 text-black"
            >
            {{ !isShowQr ? $t('Show QR Code') : $t('Hide QR Code') }}
          </vs-button>
        </div>
        <vs-button
          @click="editToggle()"
          color="primary"
          type="filled"
          class="submit-button"
          >
          {{ $t('Edit') }}
        </vs-button>
      </div>
		</div>
	</div>
</template>

<script>
/* eslint-disable camelcase */
import {
  duplicateVar,
  getFirstCharacter,
  generateColorByAlphabet,
} from '@/lib/helper';
import ProfileQR from '@/components/events/profile/ProfileQR.vue';

export default {
  components: {
    ProfileQR,
  },
  props: {
    participant: {
      type: Object,
      default: () => {},
    },
    eventId: {
      type: Number,
      default: null,
    },
    event: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      languageOptions: [
        { value: 'no', text: 'Norsk' },
        { value: 'en', text: 'English' },
      ],
      dataId: null,
      dataEventId: null,

      dataName: '',
      dataCompany: '',
      dataEmail: '',
      dataLanguage: 'en',
      dataTable: '',
      dataCode: '',
      dataIsAssignSeat: false,
      dataNotes: '',
      dataSeat: '',
      dataRoom: '',
      dataCountry: '',

      dataImg: null,
      isSaving: false,
      isUploading: false,
      isShowQr: false,
    };
  },
  computed: {
    eventSeats() {
      const reserved = this.event && this.event.meta && this.event.meta.total_seats_reserved ? this.event.meta.total_seats_reserved : 0;
      return this.event && this.event.seats ? this.event.seats - reserved : 0;
    },
    isExtend() {
      const settings = JSON.parse(this.event.settings);
      return settings.sidebar && settings.sidebar
        .is_enable_extended_field ? settings.sidebar
          .is_enable_extended_field : false;
    },
  },
  methods: {
    showQr() {
      this.isShowQr = !this.isShowQr;
    },
    showDeleteParticipant() {
      this.$emit('showDeleteParticipant', this.participant);
    },
    setData() {
      const participant = duplicateVar(this.participant);

      this.dataId = participant.id;
      this.dataEventId = participant.event_id;
      this.dataEmail = participant.email;
      this.dataCompany = participant.company;
      this.dataName = participant.name;
      this.dataLanguage = participant.language;
      this.dataTable = participant.table;
      this.dataIsAssignSeat = participant.is_seat_confirm;
      this.dataNotes = participant.notes;
      this.dataImg = participant.avatar_thumbnail;
      this.dataCode = participant.code;
      this.dataSeat = participant.seat;
      this.dataRoom = participant.room;
      this.dataCountry = participant.country;
    },

    editToggle() {
      this.$emit('editToggle');
    },
    getInitial(str) {
      return getFirstCharacter(str);
    },
    getColor(str) {
      return generateColorByAlphabet(str);
    },
  },
  mounted() {
  },
  created() {
    this.setData();
  },
  destroyed() {
  },
};
</script>
