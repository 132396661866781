<template>
	<modal
		class="modal-profie"
		size="small-medium"
		:hide-footer="true"
		:show-close="true"
		:isEnableKeyUp="false"
		@close="close"
		v-if="show">
		<div slot="header">
			<h5 class="modal-title" v-if="Object.entries(this.participant).length === 0">{{  $t('Add') }} <span>{{ $t('Participant') }}</span></h5>
			<h5 class="modal-title" v-if="Object.entries(this.participant).length > 0 && !isEdit && participant.user && participant.user && participant.user.name">{{ $t('Profile') }} <span>{{ participant.user.name }}</span></h5>
			<h5 class="modal-title" v-if="Object.entries(this.participant).length > 0 && isEdit">{{  $t('Edit') }} <span>{{ $t('Participant') }}</span></h5>
		</div>
		<div slot="body">
			<div>
				<participant-edit-form @close="close" @add="add" :participant="participant" :eventId="eventId" :event="event" v-if="isEdit || Object.entries(this.participant).length === 0" />
				<participant-details @showDeleteParticipant="showDeleteParticipant" @editToggle="editToggle" :event="event" :participant="participant" v-if="!isEdit && Object.entries(this.participant).length > 0" />
			</div>
		</div>
	</modal>
</template>

<script>

import { mapGetters } from 'vuex';
import Modal from '@/components/Modal.vue';
import ParticipantEditForm from '@/components/participants/form/ParticipantEditForm.vue';
import ParticipantDetails from '@/components/participants/form/ParticipantDetails.vue';

export default {
  components: {
    Modal,
    ParticipantEditForm,
    ParticipantDetails,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    eventId: {
      type: Number,
      default: null,
    },
    event: {
      type: Object,
      default: () => {},
    },
    participant: {
      type: Object,
      default: null,
    },
    isEditFromParent: {
      type: Boolean,
      default: false,
    },
    isDetailsFromParent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      accessCode: null,
      isSubmitting: false,
      isEdit: false,
      isSaving: false,
      dataParticipant: {
        name: '',
        company: '',
        email: '',
        language: '',
      },
    };
  },
  watch: {
    show() {
      if (this.show) {
        if (this.isEditFromParent) {
          this.isEdit = true;
        }
        if (this.isDetailsFromParent) {
          this.isEdit = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    }),
  },
  methods: {
    close() {
      this.$emit('close');
      this.isEdit = false;
    },
    add(item) {
      this.$emit('add', item);
    },
    editToggle() {
      this.isEdit = true;
    },
    showDeleteParticipant(item) {
      this.$emit('showDeleteParticipant', item);
    },
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>
