<template>
<div v-if="qrCode && !isGenerate" class="space-y-4 flex justify-center">
  <img width="240px" :src="qrCode" alt="Kobla" />
</div>
<div class="text-center mt-6" v-else>
  {{ $t('Generating your QR code...') }}
</div>
</template>

<script>
import { getAxiosErrorMessage } from '@/lib/helper';
import participant from '../../../api/participant';


export default {
  components: {
  },
  props: {
    profile: {
      type: Object,
      default: () => null,
    },
    widthQr: {
      type: Number,
      default: () => 400,
    },
  },
  data() {
    return {
      user: null,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      isGenerate: false,
      qrCode: '',
    };
  },
  computed: {
    language() {
      const lang = this.user && this.user.language ? this.user.language : 'en';
      return lang === 'en' ? 'English' : 'Norsk';
    },
  },
  methods: {
    generateQR() {
      this.$vs.loading();
      this.isGenerate = true;
      const callback = (response) => {
        this.user = response.data;
        this.user = this.profile;
        this.user.qr_code = response.data.qr_code;
        this.isGenerate = false;
        this.qrCode = response.data?.qr_code;
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('User'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isGenerate = false;
        this.$vs.loading.close();
      };
      const params = {
        participant_id: this.user?.id,
      };
      participant.generateQR(params, callback, errorCallback);
    },
  },
  mounted() {
    if (this.user && !this.user.qr_code) {
      this.generateQR(this.user.id);
    }
  },
  created() {
    this.user = this.profile;
    this.qrCode = this.user?.qr_code || '';
  },
  destroyed() {
  },
};
</script>
