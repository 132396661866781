<template>
	<vs-popup
		class="popup-size-large"
		:title="$t('Attendee Profile')"
		:active.sync="isVisible"
		button-close-hidden>
		<div class="px-4 pb-8">
			<div class="vx-row" v-if="item && item.user">
				<div class="vx-col sm:w-1/2 w-full mb-2">
					<!-- Image Container -->
					<div class="img-container w-64 flex items-center justify-center mb-5">
						<img
							:src="profilePicture"
							alt="img"
							class="responsive"
							v-if="profilePicture"
						/>
						<img
							src="@/assets/images/file-icons/default-img.jpg"
							alt="asd"
							class="responsive"
							v-else
						/>
					</div>
				</div>
				<div class="vx-col sm:w-1/2 w-full mb-2">
					<!-- Full Name -->
					<div class="mb-5">
						<div class="text-secondary text-sm">{{ $t('Full Name') }}</div>
						<div class="">{{ item.user.name }}</div>
					</div>
					<!-- Email -->
					<div class="mb-5" v-if="isEmailVisible">
						<div class="text-secondary text-sm">{{ $t('Email') }}</div>
						<div class="">{{ item.user.email }}</div>
					</div>
					<!-- Phone -->
					<div class="mb-5">
						<div class="text-secondary text-sm">{{ $t('Mobile Phone') }}</div>
						<div class="">{{ item.user.mobile_phone || '-' }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="vs-popup--footer flex align-middle justify-end">
			<!-- Delete -->
			<div
				class="btn-add-new px-4 py-25 mr-4 vs-button vs-button-danger vs-button-filled rounded-lg cursor-pointer"
				@click="remove"
				v-if="!isOwner">
				<span class="">{{ $t('Delete This Attendee') }}</span>
			</div>
			<!-- Edit -->
			<div
				class="btn-add-new px-4 py-25 mr-4 vs-button vs-button-primary vs-button-filled rounded-lg cursor-pointer"
				@click="edit">
				<span class="">{{ $t('Edit Attendee Data') }}</span>
			</div>
			<!-- Cancel -->
			<div
				class="btn-add-new px-4 py-25 vs-button vs-button-light vs-button-filled rounded-lg cursor-pointer"
				@click="close">
				<span class="">{{ $t('Cancel') }}</span>
			</div>
		</div>
	</vs-popup>

</template>

<script>
export default {
  name: 'ParticipantDetails',
  props: {
    item: {
      type: Object,
      default: null,
    },
    event: {
      type: Object,
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    edit() {
      this.$emit('edit', this.item);
    },
    remove() {
      this.$emit('remove', this.item);
    },
  },
  computed: {
    isOwner() {
      const isOwner = this.event && this.item && this.event.user_id === this.item.user_id;
      return isOwner;
    },
    profilePicture() {
      return this.item.user ? this.item.user.picture : null;
    },
    settings() {
      const settings = this.item && this.item.user && this.item.user.settings ? JSON.parse(this.item.user.settings) : null;
      return settings;
    },
    isEmailVisible() {
      // const settings = this.settings;
      // const isVisible = settings && settings.is_email_visible;
      const isVisible = this.item.user ? this.item.user.is_email_visible : false;
      return isVisible;
    },
    isListVisible() {
      const settings = this.settings;
      const isVisible = settings && settings.is_list_visible;
      return isVisible;
    },
    isPhoneVisible() {
      // const settings = this.settings;
      // const isVisible = settings && settings.is_phone_visible;
      const isVisible = this.item.user && this.item.user ? this.item.user.is_mobile_phone_visible : false;
      return isVisible;
    },
    isSpeaker() {
      const roles = this.item.roles ? JSON.parse(this.item.roles) : [];
      const isSpeaker = roles.includes('speaker');
      return isSpeaker;
    },
  },
};
</script>
