<template>
	<div>
		<vs-prompt
      v-show="isModalBroadcast"
      class="broadcast"
      :title="$t('Broadcast Message')"
      :disabled-outside="true"
      :buttons-hidden="true"
      @close="closeBroadcast()"
      :active.sync="isModalActiveLocal">
      <!-- reply section -->
      <div class="settings-section relative pl-6 pr-3">
        <!-- template -->
        <!-- recipient -->
        <div>
          <div class="font-medium text-xl mb-4">{{ $t('Recipient(s)') }}</div>
            <ul class="flex p-0">
              <li v-for="target in targets" :key="target.value">
                <vs-radio
                  v-model="dataTarget"
                  :vs-value="target.value"
                  class="mr-8"
                >
                  {{ $t(target.title) }}
                </vs-radio>
              </li>
            </ul>
        </div>
        <div>
          <!-- broadcast type -->
          <vs-select class="w-full mt-4" v-model="dataType" :placeholder="$t('Select Broadcast template')" @change="onChangeTemplate(dataType)">
            <vs-select-item :value="status.value" :text="status.title" v-for="status in types" :key="status.value"/>
            <vs-select-item v-if="previousTypes && previousTypes.length > 0" class="previous-select" text="Previous">
            </vs-select-item>
            <vs-select-item :value="status.value" :text="status.title" v-for="(status, index) in previousTypes" :key="index"/>
          </vs-select>
        </div>
        <div>
          <!-- select by selected -->
          <vs-select multiple autocomplete class="w-full mt-4" v-if="dataTarget === 'selected'" v-model="dataRecipients" :placeholder="$t('Select Recipient(s)')">
            <vs-select-item :value="participant.id" :text="participant.name" v-for="(participant, index) in participants" :key="index"/>
          </vs-select>
        </div>
        <div>
          <!-- select by status -->
          <vs-select class="w-full mt-4" v-if="dataTarget === 'status'" v-model="dataStatus" placeholder="Select status" @change="onChangeStatus(dataStatus)">
            <vs-select-item  :value="status.value" :text="status.title" v-for="(status, index) in filterStatuses" :key="index"/>
          </vs-select>
        </div>
        <!-- title broadcast -->
        <vs-input v-filter-input
          class="w-full mt-4"
          name="item-title"
          :placeholder="$t('Title')"
          v-model="dataTitle"
        />
        <!-- Content -->
        <vs-textarea  v-filter-input
          class="w-full mt-4 input-message"
          name="item-content"
          rows="10"
          :placeholder="$t('Message')"
          v-model="dataContent"
        />
        <div class="flex justify-between mb-4">
          <div class="w-1/2">
            <!-- Image upload Buttons -->
            <div class="modify-img flex items-center">
              <div>
                <div v-if="dataFile" class="img-container">
                  <div class="truncate text-blue" style="width: 300px;">{{ dataFileName }}</div>
                </div>
                <input
                  type="file"
                  class="hidden"
                  ref="updateAttachment"
                  @change="updateCurrFile"
                  accept="all/*"
                />
                <div class="flex">
                  <vs-button color="secondary" type="border" class="px-4 py-1"
                    @click="$refs.updateAttachment.click()">
                    <div class="flex items-center pt-1">
                      <svg class="w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>attachment</title><path d="M7.5,18A5.5,5.5 0 0,1 2,12.5A5.5,5.5 0 0,1 7.5,7H18A4,4 0 0,1 22,11A4,4 0 0,1 18,15H9.5A2.5,2.5 0 0,1 7,12.5A2.5,2.5 0 0,1 9.5,10H17V11.5H9.5A1,1 0 0,0 8.5,12.5A1,1 0 0,0 9.5,13.5H18A2.5,2.5 0 0,0 20.5,11A2.5,2.5 0 0,0 18,8.5H7.5A4,4 0 0,0 3.5,12.5A4,4 0 0,0 7.5,16.5H17V18H7.5Z" /></svg>
                      {{$t('Attachment')}}
                    </div>
                  </vs-button>
                  <vs-button v-if="dataFile" class="ml-2 btn-icon text-black" color="pr" icon="delete" @click="removeDataFile()"></vs-button>
                  <vs-button
                    v-if="dataContent"
                    class="px-4 ml-4"
                    color="#f3f3f3"
                    text-color="black"
                    @click="embedQR()">
                    <div class="flex items-center">
                      {{ $t('Embed QR') }}
                    </div>
                  </vs-button>
                </div>
              </div>
            </div>
          </div>
          <div class="text-danger pt-2 w-1/2 text-right">
            *{{ $t('Do not edit the text in bracket [ ]') }}
          </div>
        </div>
        <!-- message broadcast -->
      </div>
      <div class="items-center flex justify-end mr-5  ">
          <vs-button
            class="px-4 mr-4"
            color="#f3f3f3"
            text-color="black"
            @click="closeBroadcast()">
            <div class="flex items-center">
                {{ $t('Cancel') }}
            </div>
          </vs-button>
          <vs-button
            class="py-2 px-6"
            @click="broadcast()">
            <div class="flex items-center">
              <svg class="w-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>bullhorn</title><path fill="currentColor" d="M12,8H4A2,2 0 0,0 2,10V14A2,2 0 0,0 4,16H5V20A1,1 0 0,0 6,21H8A1,1 0 0,0 9,20V16H12L17,20V4L12,8M21.5,12C21.5,13.71 20.54,15.26 19,16V8C20.53,8.75 21.5,10.3 21.5,12Z" /></svg>
              <div class="pt-1">{{ $t('Broadcast') }}</div>
            </div>
          </vs-button>
        </div>
    </vs-prompt>
    </div>
</template>

<script>
import participantsApi from '@/api/participant';
import fileApi from '@/api/file';
import broadcastsApi from '@/api/broadcast';
// import eventsApi from '@/api/event';
import {
  getAxiosErrorMessage, duplicateVar,
} from '@/lib/helper';
import { BROADCAST_TEMPLATES } from '@/lib/statics';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export default {
  components: {
  },
  props: {
    isModalBroadcast: {
      type: Boolean,
      default: () => false,
    },
    eventData: {
      type: Object,
      default: () => {},
    },
    idsBroadcast: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    isModalBroadcast() {
      if (this.isModalBroadcast) {
        this.isModalActiveLocal = true;
        this.fetchParticipants();
        this.getPreviousBroadcast();
        if (this.idsBroadcast) {
          this.dataRecipients = this.idsBroadcast;
          this.dataTarget = 'selected';
        }
      } else {
        this.isModalActiveLocal = false;
      }
    },
    isModalActiveLocal() {
      if (!this.isModalActiveLocal) this.$emit('closeBroadcast');
    },
  },
  data() {
    return {
      isModalActiveLocal: false,
      isSending: false,
      dataTarget: 'all',
      dataStatus: '',
      dataRecipients: [],
      participants: [],
      previousBroadcasts: [],
      dataType: '',
      dataTitle: '',
      dataContent: '',
      dataFile: null,
      dataFileName: '',
      targets: [
        { title: 'All', value: 'all' },
        { title: 'Selected', value: 'selected' },
        { title: 'Status', value: 'status' },
      ],
      types: [
        { title: 'Invitation', value: 'invitation' },
        { title: 'Reset Password', value: 'reset_password' },
        { title: 'Reminder', value: 'reminder' },
        { title: 'As Message', value: 'message' },
      ],
      previousTypes: [],
      filterStatuses: [
        { title: 'Invitation Sent', value: 'invitation_sent' },
        { title: 'Invitation broadcast failed', value: 'failed_sent_broadcast' },
        { title: 'Invitation sent failed', value: 'failed_sent_invitation' },
        { title: 'Logged In', value: 'logged_in' },
        { title: 'All', value: 'all' },
      ],
      broadcastTemplates: duplicateVar(BROADCAST_TEMPLATES),
    };
  },
  computed: {
    isFormValid() {
      return this.dataAnnouncement !== '';
    },
    slug() {
      return this.$route.params.eventSlug;
    },
  },
  methods: {
    embedQR() {
      if (!this.dataContent.includes('[QRCODE]')) {
        this.dataContent = `${this.dataContent}

[QRCODE]`;
      }
    },
    onChangeTemplate(dataSelectedTemplate) {
      if (typeof dataSelectedTemplate === 'number') {
        const indexPreviousBroadcast = this.previousBroadcasts.findIndex(({ id }) => id === dataSelectedTemplate);
        const currentBroadcast = this.previousBroadcasts[indexPreviousBroadcast];
        if (currentBroadcast.ids === '[]') {
          this.dataRecipients = [];
          this.dataTarget = 'all';
        } else {
          this.dataRecipients = JSON.parse(currentBroadcast.ids);
          this.dataTarget = 'selected';
        }
        this.dataStatus = currentBroadcast.status;
        if (this.dataStatus) {
          this.dataTarget = 'status';
          if (this.dataStatus === 'all') this.dataTarget = 'all';
        }
        this.dataTitle = currentBroadcast.title;
        this.dataContent = currentBroadcast.content;
        this.dataFile = currentBroadcast.file;
      }
      const index = this.broadcastTemplates.findIndex(({ id }) => id === dataSelectedTemplate);
      if (index !== -1) {
        this.dataTitle = this.broadcastTemplates[index].title;
        this.dataContent = this.broadcastTemplates[index].content;
      }
    },
    onChangeStatus(dataSelectedStatus) {
      this.$vs.loading();
      if (this.participants && this.participants.length > 0) {
        const filteredParticipant = this.participants.filter(participant => participant.status === dataSelectedStatus);
        const mapParticipants = filteredParticipant.map(({ id }) => id);
        this.dataRecipients = mapParticipants;
      }
      setTimeout(() => {
        this.$vs.loading.close();
      }, 500);
    },
    async broadcast() {
      this.isSending = true;
      const title = this.$t('Broadcast');
      const finalIds = duplicateVar(this.dataRecipients);
      const params = {
        participant_ids: JSON.stringify(finalIds),
        title: this.dataTitle,
        content: this.dataContent,
        type: this.dataType,
        file: this.dataFile,
      };
      if (this.dataTarget === 'all' || this.dataStatus === 'all') params.participant_ids = JSON.stringify([]);
      const callback = (response) => {
        const message = response.message;
        this.$vs.notify({
          title,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.isSending = false;
        this.dataRecipients = [];
        this.closeBroadcast();
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isSending = false;
        this.closeBroadcast();
      };
      broadcastsApi.create(this.eventData && this.eventData.id ? this.eventData.id : null, params, callback, errorCallback);
    },
    closeBroadcast() {
      this.reset();
      this.isModalActiveLocal = false;
    },
    fetchParticipants() {
      this.$vs.loading();
      this.isParticipantfetchParticipantsing = true;
      this.participants = [];
      const params = {
        page: 1,
        limit: 10000,
        event_id: this.eventData && this.eventData.id ? this.eventData.id : null,
      };
      if (this.dataStatus) params.status = this.dataStatus;

      const callback = (response) => {
        const participants = response.data;
        this.participants = participants;
        this.isFetching = false;
        this.$vs.loading.close();
      };
      const errorCallback = () => {
        this.isFetching = false;
        this.$vs.loading.close();
      };
      participantsApi.getList(params, callback, errorCallback);
    },
    updateCurrFile(input) {
      if (input.target.files && input.target.files[0]) {
        this.isUploading = true;
        const file = input.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.dataFile = e.target.result;
        };
        reader.readAsDataURL(file);

        // Upload Image
        const formData = new FormData();
        formData.append('file', file);
        const callback = (response) => {
          const url = response.data;
          this.dataFile = url;
          this.dataFileName = file.name;
          this.isUploading = false;
        };
        const errorCallback = (e) => {
          const message = getAxiosErrorMessage(e);
          this.$vs.notify({
            title: this.$t('Upload Image'),
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
          this.isUploading = false;
        };

        fileApi.upload(formData, callback, errorCallback);
      }
    },
    removeDataFile() {
      this.dataFileName = '';
      this.dataFile = null;
      this.$refs.updateAttachment.value = '';
    },
    getPreviousBroadcast() {
      this.previousTypes = [];
      this.$vs.loading();
      const params = {
        limit: 3,
        order_by: 'created_at',
        sort_by: 'desc',
      };
      const callback = (response) => {
        const broadcasts = response.data;
        this.previousBroadcasts = broadcasts.data;
        for (let index = 0; index < this.previousBroadcasts.length; index++) {
          const element = this.previousBroadcasts[index];
          const previousBroadcast = { title: `Message ${dayjs.utc(element.created_at).format('DD/MM/YYYY')}`, value: element.id };
          this.previousTypes.push(previousBroadcast);
        }
        this.isFetching = false;
        this.$vs.loading.close();
      };
      const errorCallback = () => {
        this.isFetching = false;
        this.$vs.loading.close();
      };
      broadcastsApi.getList(this.eventData && this.eventData.id ? this.eventData.id : null, params, callback, errorCallback);
    },
    reset() {
      this.dataTarget = 'all';
      this.dataStatus = '';
      this.dataType = '';
      this.dataTitle = '';
      this.dataContent = '';
      this.dataFile = null;
      this.dataFileName = '';
    },
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>
<style lang="scss">
  .settings-section {
      margin-top: 0px;
      width: 98%;
      min-height: 210px;
  }

  .approve.con-vs-dialog .vs-dialog .vs-dialog-text {
      padding: 0!important;
  }

  @media screen and (min-width: 600px) {
    .broadcast.con-vs-dialog .vs-dialog {
      min-width: 600px!important;
    }
  }
  .vs-select--options .previous-select span {
    color: #Cccccc; /* Change to your desired color */
  }
</style>
