<template>
<div>
	<!-- Image Container -->
	<div v-if="dataImg == null" class="text-center pointer disabled" @click="$refs.updateImgInput.click()">
		<vs-avatar v-if="!participant" size="70px">
			<img src="@/assets/images/file-icons/default-img.jpg" alt="asd" class="responsive pointer" />
		</vs-avatar>
		<vs-avatar class="text-4xl" :color="getColor(participant && participant.name)" :text="getInitial(participant && participant.name)" v-if="participant" size="70px">
		</vs-avatar>
	</div>
	<div v-if="dataImg != null" class="text-center" @click="$refs.updateImgInput.click()">
		<img :src="dataImg" alt="img" style="width: 70px; height: 70px" class="responsive rounded-full" />
	</div>
	<!-- Image upload Buttons -->
	<div  v-if="dataImg != null" class="flex justify-center">
		<vs-button type="flat" color="#999" @click="removeImage()">{{$t('Remove Image')}}</vs-button>
	</div>
	<input
			type="file"
			class="hidden-visibility"
			ref="updateImgInput"
			@change="updateCurrImg"
			accept="image/*"
			id="image-attendee"
	/>
	<div  class="space-y-4">
		<div>
      <label class="text-sm pl-2 text-secondary">{{ $t('Name') }}</label>
			<vs-input v-filter-input
				v-validate="'required'"
				data-vv-validate-on="blur"
				name="name"
				type="text"
				class="w-full"
				v-model="dataName"
			/>
			<span class="text-danger text-sm">{{ $t(errors.first('name')) }}</span>
		</div>
		<div>
      <label class="text-sm pl-2 text-secondary">{{ $t('Email') }}</label>
			<vs-input
				v-validate="'required|email'"
				data-vv-validate-on="blur"
				name="email"
				type="email"
				class="w-full"
				v-model="dataEmail"
			/>
			<span class="text-danger text-sm">{{ $t(errors.first('email')) }}</span>
		</div>
		<div>
      <label class="text-sm pl-2 text-secondary">{{ $t('Company') }}</label>
			<vs-input v-filter-input
				v-validate="'required'"
				data-vv-validate-on="blur"
				name="company"
				type="text"
				class="w-full"
				v-model="dataCompany"
			/>
			<span class="text-danger text-sm">{{ $t(errors.first('company')) }}</span>
		</div>
    <div v-if="isExtend">
      <label class="text-sm pl-2 text-secondary">{{ $t('Job Title') }}</label>
			<vs-input v-filter-input
				v-validate="'required'"
				data-vv-validate-on="blur"
				name="job_title"
				type="text"
				class="w-full"
				v-model="dataJobTitle"
			/>
			<span class="text-danger text-sm">{{ $t(errors.first('job_title')) }}</span>
		</div>
		<div :class="{'flex justify-between w-full': isExtend}">
      <div>
        <label class="text-sm" style="padding-left: 6px; color: #5F5F5F;">{{ $t('Language') }}</label>
        <vs-select
          name="language-settings-user"
          class="min-w-full pr-2"
          v-model="dataLanguage"
          >
          <vs-select-item
          v-for="item in languageOptions"
            :key="item.value"
            :value="item.value"
            :text="item.text"
          />
        </vs-select>
      </div>
      <div v-if="isExtend">
          <vs-input v-filter-input
            data-vv-validate-on="blur"
            name="country"
            type="text"
            :label="$t('Country')"
            :placeholder="$t('Country')"
            class="min-w-full pl-2 pr-2"
            v-model="dataCountry"
          />
          <span class="text-danger text-sm">{{ $t(errors.first('country')) }}</span>
        </div>
    </div>

    <div v-if="isExtend">
      <div>
        <label class="text-sm" style="padding-left: 6px; color: #5F5F5F;">{{ $t('Phone Number') }}</label>
        <VuePhoneNumberInput
          :translations="phoneTranslations"
          class="input-number"
          :default-country-code="defaultCountryCode"
          :preferred-countries="['NO', 'US', 'GB']"
          @update="onUpdatePhone"
          v-model="dataMobilePhone"
          :no-validator-state="true"
          />
      </div>
      <!-- extended -->
      <div class="w-full mt-4 flex justify-between">
        <div>
          <vs-input v-filter-input
            data-vv-validate-on="blur"
            name="checked_in_at"
            type="text"
            :label="$t('Checked In')"
            :placeholder="$t('Checked In')"
            class="min-w-full pr-2"
            disabled
            :value="dateFormat(dataCheckedIn)"
          />
          <span class="text-danger text-sm">{{ $t(errors.first('checked_in_at')) }}</span>
        </div>

        <div>
          <vs-input v-filter-input
            data-vv-validate-on="blur"
            name="room"
            type="text"
            :label="$t('Room')"
            :placeholder="$t('Room')"
            class="min-w-full pl-2 pr-2"
            v-model="dataRoom"
          />
          <span class="text-danger text-sm">{{ $t(errors.first('room')) }}</span>
        </div>
      </div>

      <div class="w-full flex mt-2 justify-between">
        <div>
          <vs-input v-filter-input
            data-vv-validate-on="blur"
            name="table"
            type="text"
            :label="$t('Table')"
            :placeholder="$t('Table')"
            class="min-w-full pr-2"
            v-model="dataTable"
          />
          <span class="text-danger text-sm">{{ $t(errors.first('table')) }}</span>
        </div>

        <div>
          <vs-input v-filter-input
            data-vv-validate-on="blur"
            name="seat"
            type="text"
            :label="$t('Seat')"
            :placeholder="$t('Seat')"
            class="min-w-full pl-2 pr-2 mb-4"
            v-model="dataSeat"
          />
          <span class="text-danger text-sm">{{ $t(errors.first('seat')) }}</span>
        </div>
      </div>
    </div>

    <!-- only if hybrid -->
    <vs-checkbox :disabled="parseInt(eventSeats) === 0" v-if="event && event.is_hybrid" v-model="dataIsAssignSeat" class="mb-4 mt-8">{{$t('Assign Seat')}} ({{ eventSeats }} {{ $t('seats left') }})</vs-checkbox>

    <div v-if="dataNotes">
      <div class="mt-8 text-secondary">{{ $t('Notes') }}</div>
      {{ dataNotes }}
    </div>

		<div class="text-right">
			<vs-button
				:disabled="!isFormValid"
				@click="submitData()"
				color="primary"
				type="filled"
				class="submit-button mt-10"
				>
				{{ $t('Save') }}
			</vs-button>
		</div>
	</div>
</div>
</template>

<script>
/* eslint-disable camelcase */
import axios from 'axios';
import participantsApi from '@/api/participant';
import fileApi from '@/api/file';
import {
  duplicateVar,
  getFirstCharacter,
  generateColorByAlphabet,
  getAxiosErrorMessage,
  dateFormat,
} from '@/lib/helper';
import VuePhoneNumberInput from 'vue-phone-number-input';

export default {
  components: {
    VuePhoneNumberInput,
  },
  props: {
    participant: {
      type: Object,
      default: () => {},
    },
    eventId: {
      type: Number,
      default: null,
    },
    event: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      languageOptions: [
        { value: 'no', text: 'Norsk' },
        { value: 'en', text: 'English' },
      ],
      dataId: null,
      dataEventId: null,

      dataName: '',
      dataCompany: '',
      dataJobTitle: '',
      dataEmail: '',
      dataLanguage: 'no',
      dataIsAssignSeat: false,
      dataNotes: '',
      dataCountry: '',
      dataRoom: '',
      dataTable: '',
      dataSeat: '',
      dataCheckedIn: '',
      dataMobilePhone: '',

      dataImg: null,
      isSaving: false,
      isUploading: false,

      resultsPhone: {},
      phoneTranslations: {
        countrySelectorLabel: this.$t('Code Country'),
        countrySelectorError: this.$t('Choose one country'),
        phoneNumberLabel: this.$t('Phone Number'),
        example: this.$t('Example:'),
      },
      defaultCountryCode: 'NO', // default to NO initially
    };
  },
  computed: {
    params() {
      const params = {
        id: this.dataId,
        event_id: this.dataEventId,
        email: this.dataEmail,
        name: this.dataName,
        company: this.dataCompany,
        job_position: this.dataJobTitle,
        avatar: this.dataImg,
        language: this.dataLanguage,
        is_seat_confirm: this.dataIsAssignSeat,
        seat: this.dataSeat,
        table: this.dataTable,
        room: this.dataRoom,
        mobile_phone: this.resultsPhone.countryCallingCode + this.resultsPhone.nationalNumber,
        country: this.dataCountry,
      };
      return params;
    },
    isFormValid() {
      return (
        !this.errors.any()
				&& this.dataEmail !== ''
				&& this.dataName !== ''
				&& this.dataLanguage !== ''
				&& this.dataCompany !== ''
				&& !this.isUploading
      );
    },
    eventSeats() {
      const reserved = this.event && this.event.meta && this.event.meta.total_seats_reserved ? this.event.meta.total_seats_reserved : 0;
      return this.event && this.event.seats ? this.event.seats - reserved : 0;
    },

    isExtend() {
      const settings = JSON.parse(this.event.settings);
      return settings.sidebar && settings.sidebar
        .is_enable_extended_field ? settings.sidebar
          .is_enable_extended_field : false;
    },
  },
  methods: {
    dateFormat(date) {
      if (date) return dateFormat(date, 'DD. MM. YYYY HH:mm');
      return '-';
    },
    onUpdatePhone(payload) {
      this.resultsPhone = payload;
    },

    setData() {
      const participant = duplicateVar(this.participant);

      this.dataId = participant.id;
      this.dataEventId = participant.event_id;
      this.dataEmail = participant.email;
      this.dataCompany = participant.company;
      this.dataJobTitle = participant.job_position;
      this.dataName = participant.name;
      this.dataLanguage = participant.language;
      this.dataIsAssignSeat = participant.is_seat_confirm;
      this.dataNotes = participant.notes;
      this.dataImg = participant.avatar_thumbnail;
      this.dataMobilePhone = participant.mobile_phone;
      this.dataSeat = participant.seat;
      this.dataRoom = participant.room;
      this.dataTable = participant.table;
      this.dataCheckedIn = participant.checked_in_at;
      this.dataCountry = participant.country;
      this.initValues();
    },
    initValues() {
      if (this.participant.id) return;
      this.dataId = null;
      this.dataEventId = this.eventId;
      this.dataEmail = '';
      this.dataCompany = '';
      this.dataJobTitle = '';
      this.dataName = '';
      this.dataIsAssignSeat = false;
      this.dataImg = null;
      this.dataLanguage = 'en';
      this.dataNotes = '';
      this.dataMobilePhone = '';
      this.dataSeat = '';
      this.dataRoom = '';
      this.dataTable = '';
      this.dataCheckedIn = '';
      this.dataCountry = '';
    },
    submitData() {
      const submit = (result) => {
        if (!result) return;
        this.isSaving = true;
        const title = this.$t('Participant');
        const isUpdate = !!this.dataId;
        const params = this.params;
        const callback = (response) => {
          const item = response.data;
          this.$store.dispatch('setParticipant', item);
          const message = response.message;
          this.$emit('add', item);
          this.$emit('close');
          this.initValues();
          this.$vs.notify({
            title,
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success',
          });
          this.isSaving = false;
        };
        const errorCallback = (e) => {
          const message = getAxiosErrorMessage(e);
          this.$vs.notify({
            title,
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
          this.isSaving = false;
        };
        if (isUpdate) {
          participantsApi.update(this.dataId, params, callback, errorCallback);
        } else {
          participantsApi.create(this.dataEventId, params, callback, errorCallback);
        }
      };
      this.$validator.validateAll().then(submit);
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.isUploading = true;
        const file = input.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.dataImg = e.target.result;
        };
        reader.readAsDataURL(file);

        // Upload Image
        const formData = new FormData();
        formData.append('file', file);

        const callback = (response) => {
          const url = response.data;
          this.dataImg = url;
          this.isUploading = false;
        };

        const errorCallback = (e) => {
          const message = getAxiosErrorMessage(e);
          this.$vs.notify({
            title: this.$t('Upload Image'),
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
          this.isUploading = false;
        };

        fileApi.upload(formData, callback, errorCallback);
      }
    },
    removeImage() {
      this.dataImg = null;
      this.$refs.updateImgInput.value = '';
    },
    getInitial(str) {
      return getFirstCharacter(str);
    },
    getColor(str) {
      return generateColorByAlphabet(str);
    },
    async getCountryCode() {
      try {
        const response = await axios.get('https://ip2c.org/s');
        const data = response.data.split(';');
        if (data[0] === '1') {
          this.defaultCountryCode = data[1]; // data[1] contains the country code
        } else {
          console.error('Unable to determine country code.');
        }
      } catch (error) {
        console.error('Error fetching country code:', error);
      }
    },
  },
  mounted() {
    this.initValues();
    this.getCountryCode();
  },
  created() {
    this.setData();
  },
  destroyed() {
  },
};
</script>
